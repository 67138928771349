<template>
  <div>
    <HeaderFive>
      <img slot="logo" src="../assets/img/logo/portfolio.png" />
    </HeaderFive>
    <!-- End Header -->

    <!-- Start Bradcaump area -->
    <div class="bradcaump_area">
      <div class="parallaxContainer paralaxbg--3" data-black-overlay="6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center bradcaump_inner">
                <h2 class="bradcaump-title">Etsy Items</h2>
                <p></p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- End Bradcaump area -->
    <br />
    <div
      class="etsy-border"
      v-if="shopId"
      id="shop-category"
      :section-id="shopId"
    ></div>
    <div class="etsy-border" v-else id="item-details"></div>

    <Footer />
    <!-- End Footer -->
  </div>
</template>

<script>
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/Footer";
export default {
  components: {
    HeaderFive,
    Footer,
  },
  data() {
    return {
      shopId: this.$route.query.category,
      itemId: this.$route.query.category,
    };
  },
};
</script>

<style lang="scss" scoped>
.etsy-border {
  margin: 7.5px;
}
</style>
