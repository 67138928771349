<template>
  <!-- Footer -->
  <footer class="-rn-footer" id="footer">
    <div class="rn-footer-wrapper footer">
      <v-container fluid>
        <v-row>
          <v-col lg="12">
            <div class="footer-top">
              <div class="content">
                <span>Planning a workshop?</span>
                <h4>Contact Me</h4>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer-wrapper ptb--100">
        <v-container fluid>
          <v-row>
            <!-- Start Single Wedget -->
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Present Address</h3>
                <div class="content">
                  <p>
                    Greater Boston Area
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Contact</h3>
                <div class="content">
                  <div class="address">
                    <p>
                      <a href="mailto:joanieskidz@gmail.com">joanieskidz@gmail.com</a>
                    </p>
                    <p><a href="tel:018024480712">802-448-0712</a></p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Quick Link</h3>
                <div class="content">
                  <ul class="quick-link">
                    <li v-for="(link, i) in quickLink" :key="i">
                      <a :href="link.RouteName">{{
                        link.name
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <FooterTwo />
  </footer>
  <!--// Footer -->
</template>

<script>
  import FooterTwo from "./FooterTwo";
  export default {
    components: {
      FooterTwo,
    },
    data() {
      return {
        quickLink: [
          {
            name: "Etsy",
            RouteName: "https://www.etsy.com/shop/joanieskids",
          },
          {
            name: "Facebook",
            RouteName: "https://www.facebook.com/joan.main3",
          }
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
